import { Hub, useHubSwitch } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function Index() {
    const router = useRouter();
    const { hub } = useHubSwitch();

    useEffect(() => {
        if (hub === Hub.Download) router.replace('/d');
        if (hub === Hub.Stream) router.replace('/s');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}
